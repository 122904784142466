import * as dayjs from 'dayjs';
import { IFestivalShow } from 'app/entities/festival-show/festival-show.model';
import { IShowGenre } from 'app/entities/show-genre/show-genre.model';
import { IEntertainmentPersonShowRole } from 'app/entities/entertainment-person-show-role/entertainment-person-show-role.model';
import { IFestival } from '../festival/festival.model';

export interface IShow {
  id?: number;
  name?: string | null;
  language?: string | null;
  synopsis?: string | null;
  releaseDate?: dayjs.Dayjs | null;
  createdAt?: dayjs.Dayjs | null;
  updatedAt?: dayjs.Dayjs | null;
  poster?: string | null;
  festivalShows?: IFestivalShow[] | null;
  showGenres?: IShowGenre[] | null;
  genre?: string[] | null;
  entertainmentPersonShowRoles?: IEntertainmentPersonShowRole[] | null;
  festivalId?: number | null;
  entertainmentPersonId?: { personId: number | null; roleIds: number[] | null }[] | null;
  genreId?: number[];
  entertainmentRoleId?: number[];
  festivalName?: string | null;
}

export class Show implements IShow {
  constructor(
    public id?: number,
    public name?: string | null,
    public language?: string | null,
    public synopsis?: string | null,
    public releaseDate?: dayjs.Dayjs | null,
    public createdAt?: dayjs.Dayjs | null,
    public updatedAt?: dayjs.Dayjs | null,
    public poster?: string | null,
    public festivalShows?: IFestivalShow[] | null,
    public showGenres?: IShowGenre[] | null,
    public genre?: string[] | null,
    public entertainmentPersonShowRoles?: IEntertainmentPersonShowRole[] | null
  ) {}
}

export function getShowIdentifier(show: IShow): number | undefined {
  return show.id;
}
