import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IEntertainmentRole, getEntertainmentRoleIdentifier } from '../entertainment-role.model';

export type EntityResponseType = HttpResponse<IEntertainmentRole>;
export type EntityArrayResponseType = HttpResponse<IEntertainmentRole[]>;

@Injectable({ providedIn: 'root' })
export class EntertainmentRoleService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/entertainment-roles');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(entertainmentRole: IEntertainmentRole): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(entertainmentRole);
    return this.http
      .post<IEntertainmentRole>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(entertainmentRole: IEntertainmentRole): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(entertainmentRole);
    return this.http
      .put<IEntertainmentRole>(`${this.resourceUrl}/${getEntertainmentRoleIdentifier(entertainmentRole) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(entertainmentRole: IEntertainmentRole): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(entertainmentRole);
    return this.http
      .patch<IEntertainmentRole>(`${this.resourceUrl}/${getEntertainmentRoleIdentifier(entertainmentRole) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IEntertainmentRole>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IEntertainmentRole[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addEntertainmentRoleToCollectionIfMissing(
    entertainmentRoleCollection: IEntertainmentRole[],
    ...entertainmentRolesToCheck: (IEntertainmentRole | null | undefined)[]
  ): IEntertainmentRole[] {
    const entertainmentRoles: IEntertainmentRole[] = entertainmentRolesToCheck.filter(isPresent);
    if (entertainmentRoles.length > 0) {
      const entertainmentRoleCollectionIdentifiers = entertainmentRoleCollection.map(
        entertainmentRoleItem => getEntertainmentRoleIdentifier(entertainmentRoleItem)!
      );
      const entertainmentRolesToAdd = entertainmentRoles.filter(entertainmentRoleItem => {
        const entertainmentRoleIdentifier = getEntertainmentRoleIdentifier(entertainmentRoleItem);
        if (entertainmentRoleIdentifier == null || entertainmentRoleCollectionIdentifiers.includes(entertainmentRoleIdentifier)) {
          return false;
        }
        entertainmentRoleCollectionIdentifiers.push(entertainmentRoleIdentifier);
        return true;
      });
      return [...entertainmentRolesToAdd, ...entertainmentRoleCollection];
    }
    return entertainmentRoleCollection;
  }

  protected convertDateFromClient(entertainmentRole: IEntertainmentRole): IEntertainmentRole {
    return Object.assign({}, entertainmentRole, {
      createdAt: entertainmentRole.createdAt?.isValid() ? entertainmentRole.createdAt.toJSON() : undefined,
      updatedAt: entertainmentRole.updatedAt?.isValid() ? entertainmentRole.updatedAt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdAt = res.body.createdAt ? dayjs(res.body.createdAt) : undefined;
      res.body.updatedAt = res.body.updatedAt ? dayjs(res.body.updatedAt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((entertainmentRole: IEntertainmentRole) => {
        entertainmentRole.createdAt = entertainmentRole.createdAt ? dayjs(entertainmentRole.createdAt) : undefined;
        entertainmentRole.updatedAt = entertainmentRole.updatedAt ? dayjs(entertainmentRole.updatedAt) : undefined;
      });
    }
    return res;
  }
}
