import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IFestival, getFestivalIdentifier } from '../festival.model';

export type EntityResponseType = HttpResponse<IFestival>;
export type EntityArrayResponseType = HttpResponse<IFestival[]>;

@Injectable({ providedIn: 'root' })
export class FestivalService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/festivals');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(festival: IFestival): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(festival);
    return this.http
      .post<IFestival>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(festival: IFestival): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(festival);
    return this.http
      .put<IFestival>(`${this.resourceUrl}/${getFestivalIdentifier(festival) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(festival: IFestival): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(festival);
    return this.http
      .patch<IFestival>(`${this.resourceUrl}/${getFestivalIdentifier(festival) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IFestival>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IFestival[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addFestivalToCollectionIfMissing(festivalCollection: IFestival[], ...festivalsToCheck: (IFestival | null | undefined)[]): IFestival[] {
    const festivals: IFestival[] = festivalsToCheck.filter(isPresent);
    if (festivals.length > 0) {
      const festivalCollectionIdentifiers = festivalCollection.map(festivalItem => getFestivalIdentifier(festivalItem)!);
      const festivalsToAdd = festivals.filter(festivalItem => {
        const festivalIdentifier = getFestivalIdentifier(festivalItem);
        if (festivalIdentifier == null || festivalCollectionIdentifiers.includes(festivalIdentifier)) {
          return false;
        }
        festivalCollectionIdentifiers.push(festivalIdentifier);
        return true;
      });
      return [...festivalsToAdd, ...festivalCollection];
    }
    return festivalCollection;
  }

  protected convertDateFromClient(festival: IFestival): IFestival {
    return Object.assign({}, festival, {
      festivalDate: festival.festivalDate?.isValid() ? festival.festivalDate.toJSON() : undefined,
      endDate: festival.endDate?.isValid() ? festival.endDate.toJSON() : undefined,
      createdAt: festival.createdAt?.isValid() ? festival.createdAt.toJSON() : undefined,
      updatedAt: festival.updatedAt?.isValid() ? festival.updatedAt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.festivalDate = res.body.festivalDate ? dayjs(res.body.festivalDate) : undefined;
      res.body.endDate = res.body.endDate ? dayjs(res.body.endDate) : undefined;
      res.body.createdAt = res.body.createdAt ? dayjs(res.body.createdAt) : undefined;
      res.body.updatedAt = res.body.updatedAt ? dayjs(res.body.updatedAt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((festival: IFestival) => {
        festival.festivalDate = festival.festivalDate ? dayjs(festival.festivalDate) : undefined;
        festival.endDate = festival.endDate ? dayjs(festival.endDate) : undefined;
        festival.createdAt = festival.createdAt ? dayjs(festival.createdAt) : undefined;
        festival.updatedAt = festival.updatedAt ? dayjs(festival.updatedAt) : undefined;
      });
    }
    return res;
  }
}
