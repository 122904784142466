import * as dayjs from 'dayjs';
import { IEntertainmentPersonShowRole } from 'app/entities/entertainment-person-show-role/entertainment-person-show-role.model';

export interface IEntertainmentRole {
  id?: number;
  name?: string | null;
  createdAt?: dayjs.Dayjs | null;
  updatedAt?: dayjs.Dayjs | null;
  entertainmentPersonShowRoles?: IEntertainmentPersonShowRole[] | null;
  entertainmentPersonId?: number | null;
}

export class EntertainmentRole implements IEntertainmentRole {
  constructor(
    public id?: number,
    public name?: string | null,
    public createdAt?: dayjs.Dayjs | null,
    public updatedAt?: dayjs.Dayjs | null,
    public entertainmentPersonShowRoles?: IEntertainmentPersonShowRole[] | null
  ) {}
}

export function getEntertainmentRoleIdentifier(entertainmentRole: IEntertainmentRole): number | undefined {
  return entertainmentRole.id;
}
