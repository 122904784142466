import * as dayjs from 'dayjs';
import { IContact } from 'app/entities/contact/contact.model';

export interface IContactType {
  id?: number;
  name?: string | null;
  createdAt?: dayjs.Dayjs | null;
  updatedAt?: dayjs.Dayjs | null;
  contacts?: IContact[] | null;
}

export class ContactType implements IContactType {
  constructor(
    public id?: number,
    public name?: string | null,
    public createdAt?: dayjs.Dayjs | null,
    public updatedAt?: dayjs.Dayjs | null,
    public contacts?: IContact[] | null
  ) {}
}

export function getContactTypeIdentifier(contactType: IContactType): number | undefined {
  return contactType.id;
}
