import * as dayjs from 'dayjs';
import { IContact } from 'app/entities/contact/contact.model';
import { IEntertainmentPersonShowRole } from 'app/entities/entertainment-person-show-role/entertainment-person-show-role.model';

export interface IEntertainmentPerson {
  id?: number;
  name?: string | null;
  title?: string | null;
  tagline?: string | null;
  pic?: string | null;
  website?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  instagram?: string | null;
  awards?: string | null;
  createdAt?: dayjs.Dayjs | null;
  updatedAt?: dayjs.Dayjs | null;
  contacts?: IContact[] | null;
  entertainmentPersonShowRoles?: IEntertainmentPersonShowRole[] | null;
}

export class EntertainmentPerson implements IEntertainmentPerson {
  constructor(
    public id?: number,
    public name?: string | null,
    public title?: string | null,
    public tagline?: string | null,
    public twitter?: string | null,
    public website?: string | null,
    public facebook?: string | null,
    public instagram?: string | null,
    public awards?: string | null,
    public createdAt?: dayjs.Dayjs | null,
    public updatedAt?: dayjs.Dayjs | null,
    public contacts?: IContact[] | null,
    public entertainmentPersonShowRoles?: IEntertainmentPersonShowRole[] | null
  ) {}
}

export function getEntertainmentPersonIdentifier(entertainmentPerson: IEntertainmentPerson): number | undefined {
  return entertainmentPerson.id;
}
