import * as dayjs from 'dayjs';
import { IShowGenre } from 'app/entities/show-genre/show-genre.model';

export interface IGenre {
  id?: number;
  name?: string | null;
  createdAt?: dayjs.Dayjs | null;
  updatedAt?: dayjs.Dayjs | null;
  showGenres?: IShowGenre[] | null;
}

export class Genre implements IGenre {
  constructor(
    public id?: number,
    public name?: string | null,
    public createdAt?: dayjs.Dayjs | null,
    public updatedAt?: dayjs.Dayjs | null,
    public showGenres?: IShowGenre[] | null
  ) {}
}

export function getGenreIdentifier(genre: IGenre): number | undefined {
  return genre.id;
}
