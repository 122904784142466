import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IGenre, getGenreIdentifier } from '../genre.model';

export type EntityResponseType = HttpResponse<IGenre>;
export type EntityArrayResponseType = HttpResponse<IGenre[]>;

@Injectable({ providedIn: 'root' })
export class GenreService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/genres');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(genre: IGenre): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(genre);
    return this.http
      .post<IGenre>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(genre: IGenre): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(genre);
    return this.http
      .put<IGenre>(`${this.resourceUrl}/${getGenreIdentifier(genre) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(genre: IGenre): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(genre);
    return this.http
      .patch<IGenre>(`${this.resourceUrl}/${getGenreIdentifier(genre) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IGenre>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IGenre[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addGenreToCollectionIfMissing(genreCollection: IGenre[], ...genresToCheck: (IGenre | null | undefined)[]): IGenre[] {
    const genres: IGenre[] = genresToCheck.filter(isPresent);
    if (genres.length > 0) {
      const genreCollectionIdentifiers = genreCollection.map(genreItem => getGenreIdentifier(genreItem)!);
      const genresToAdd = genres.filter(genreItem => {
        const genreIdentifier = getGenreIdentifier(genreItem);
        if (genreIdentifier == null || genreCollectionIdentifiers.includes(genreIdentifier)) {
          return false;
        }
        genreCollectionIdentifiers.push(genreIdentifier);
        return true;
      });
      return [...genresToAdd, ...genreCollection];
    }
    return genreCollection;
  }

  protected convertDateFromClient(genre: IGenre): IGenre {
    return Object.assign({}, genre, {
      createdAt: genre.createdAt?.isValid() ? genre.createdAt.toJSON() : undefined,
      updatedAt: genre.updatedAt?.isValid() ? genre.updatedAt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdAt = res.body.createdAt ? dayjs(res.body.createdAt) : undefined;
      res.body.updatedAt = res.body.updatedAt ? dayjs(res.body.updatedAt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((genre: IGenre) => {
        genre.createdAt = genre.createdAt ? dayjs(genre.createdAt) : undefined;
        genre.updatedAt = genre.updatedAt ? dayjs(genre.updatedAt) : undefined;
      });
    }
    return res;
  }
}
