import * as dayjs from 'dayjs';
import { IContact } from 'app/entities/contact/contact.model';
import { IFestivalShow } from 'app/entities/festival-show/festival-show.model';

export interface IFestival {
  id?: number;
  name?: string | null;
  synopsis?: string | null;
  place?: string | null;
  category?: string | null;
  language?: string | null;
  website?: string | null;
  poster?: string | null;
  festivalDate?: dayjs.Dayjs | null;
  endDate?: dayjs.Dayjs | null;
  createdAt?: dayjs.Dayjs | null;
  updatedAt?: dayjs.Dayjs | null;
  contacts?: IContact[] | null;
  festivalShows?: IFestivalShow[] | null;
}

export class Festival implements IFestival {
  constructor(
    public id?: number,
    public name?: string | null,
    public synopsis?: string | null,
    public place?: string | null,
    public category?: string | null,
    public language?: string | null,
    public website?: string | null,
    public poster?: string | null,
    public festivalDate?: dayjs.Dayjs | null,
    public endDate?: dayjs.Dayjs | null,
    public createdAt?: dayjs.Dayjs | null,
    public updatedAt?: dayjs.Dayjs | null,
    public contacts?: IContact[] | null,
    public festivalShows?: IFestivalShow[] | null
  ) {}
}

export function getFestivalIdentifier(festival: IFestival): number | undefined {
  return festival.id;
}
