import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IEntertainmentPerson, getEntertainmentPersonIdentifier } from '../entertainment-person.model';

export type EntityResponseType = HttpResponse<IEntertainmentPerson>;
export type EntityArrayResponseType = HttpResponse<IEntertainmentPerson[]>;

@Injectable({ providedIn: 'root' })
export class EntertainmentPersonService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/entertainment-people');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(entertainmentPerson: IEntertainmentPerson): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(entertainmentPerson);
    return this.http
      .post<IEntertainmentPerson>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(entertainmentPerson: IEntertainmentPerson): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(entertainmentPerson);
    return this.http
      .put<IEntertainmentPerson>(`${this.resourceUrl}/${getEntertainmentPersonIdentifier(entertainmentPerson) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(entertainmentPerson: IEntertainmentPerson): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(entertainmentPerson);
    return this.http
      .patch<IEntertainmentPerson>(`${this.resourceUrl}/${getEntertainmentPersonIdentifier(entertainmentPerson) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IEntertainmentPerson>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IEntertainmentPerson[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addEntertainmentPersonToCollectionIfMissing(
    entertainmentPersonCollection: IEntertainmentPerson[],
    ...entertainmentPeopleToCheck: (IEntertainmentPerson | null | undefined)[]
  ): IEntertainmentPerson[] {
    const entertainmentPeople: IEntertainmentPerson[] = entertainmentPeopleToCheck.filter(isPresent);
    if (entertainmentPeople.length > 0) {
      const entertainmentPersonCollectionIdentifiers = entertainmentPersonCollection.map(
        entertainmentPersonItem => getEntertainmentPersonIdentifier(entertainmentPersonItem)!
      );
      const entertainmentPeopleToAdd = entertainmentPeople.filter(entertainmentPersonItem => {
        const entertainmentPersonIdentifier = getEntertainmentPersonIdentifier(entertainmentPersonItem);
        if (entertainmentPersonIdentifier == null || entertainmentPersonCollectionIdentifiers.includes(entertainmentPersonIdentifier)) {
          return false;
        }
        entertainmentPersonCollectionIdentifiers.push(entertainmentPersonIdentifier);
        return true;
      });
      return [...entertainmentPeopleToAdd, ...entertainmentPersonCollection];
    }
    return entertainmentPersonCollection;
  }

  protected convertDateFromClient(entertainmentPerson: IEntertainmentPerson): IEntertainmentPerson {
    return Object.assign({}, entertainmentPerson, {
      createdAt: entertainmentPerson.createdAt?.isValid() ? entertainmentPerson.createdAt.toJSON() : undefined,
      updatedAt: entertainmentPerson.updatedAt?.isValid() ? entertainmentPerson.updatedAt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdAt = res.body.createdAt ? dayjs(res.body.createdAt) : undefined;
      res.body.updatedAt = res.body.updatedAt ? dayjs(res.body.updatedAt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((entertainmentPerson: IEntertainmentPerson) => {
        entertainmentPerson.createdAt = entertainmentPerson.createdAt ? dayjs(entertainmentPerson.createdAt) : undefined;
        entertainmentPerson.updatedAt = entertainmentPerson.updatedAt ? dayjs(entertainmentPerson.updatedAt) : undefined;
      });
    }
    return res;
  }
}
