import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IShow, getShowIdentifier } from '../show.model';

export type EntityResponseType = HttpResponse<IShow>;
export type EntityArrayResponseType = HttpResponse<IShow[]>;

@Injectable({ providedIn: 'root' })
export class ShowService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/shows');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(show: IShow): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(show);
    return this.http
      .post<IShow>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(show: IShow): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(show);
    return this.http
      .put<IShow>(`${this.resourceUrl}/${getShowIdentifier(show) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(show: IShow): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(show);
    return this.http
      .patch<IShow>(`${this.resourceUrl}/${getShowIdentifier(show) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IShow>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IShow[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addShowToCollectionIfMissing(showCollection: IShow[], ...showsToCheck: (IShow | null | undefined)[]): IShow[] {
    const shows: IShow[] = showsToCheck.filter(isPresent);
    if (shows.length > 0) {
      const showCollectionIdentifiers = showCollection.map(showItem => getShowIdentifier(showItem)!);
      const showsToAdd = shows.filter(showItem => {
        const showIdentifier = getShowIdentifier(showItem);
        if (showIdentifier == null || showCollectionIdentifiers.includes(showIdentifier)) {
          return false;
        }
        showCollectionIdentifiers.push(showIdentifier);
        return true;
      });
      return [...showsToAdd, ...showCollection];
    }
    return showCollection;
  }

  protected convertDateFromClient(show: IShow): IShow {
    return Object.assign({}, show, {
      releaseDate: show.releaseDate?.isValid() ? show.releaseDate.toJSON() : undefined,
      createdAt: show.createdAt?.isValid() ? show.createdAt.toJSON() : undefined,
      updatedAt: show.updatedAt?.isValid() ? show.updatedAt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.releaseDate = res.body.releaseDate ? dayjs(res.body.releaseDate) : undefined;
      res.body.createdAt = res.body.createdAt ? dayjs(res.body.createdAt) : undefined;
      res.body.updatedAt = res.body.updatedAt ? dayjs(res.body.updatedAt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((show: IShow) => {
        show.releaseDate = show.releaseDate ? dayjs(show.releaseDate) : undefined;
        show.createdAt = show.createdAt ? dayjs(show.createdAt) : undefined;
        show.updatedAt = show.updatedAt ? dayjs(show.updatedAt) : undefined;
      });
    }
    return res;
  }
}
